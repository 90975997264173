import { useReducer } from "react";
import {
  Action,
  Article,
  Banner,
  Category,
  SET_ERROR,
  SET_LOADING,
  Service,
} from "../types";

import { getAll as getBanners } from "../services/bannerService";
import { getAll as getCategories } from "../services/categoryService";
import { getAll as getServices } from "../services/packageServiceService";
import { getAll as getArticles } from "../services/articleService";

interface HomeData {
  banners: Banner[] | null;
  categories: Category[] | null;
  services: Service[] | null;
  articles: Article[] | null;
}
interface State {
  loading: boolean;
  error: string | null;
  data: HomeData | null;
}
const SET_DATA = "SET_DATA";

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: HomeData | null;
}

const reducer = (state: State, action: Action | SetDataAction): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

interface useHomeDataFetchReturnType extends State {
  fetchDatas: () => void;
}

const initialState = {
  loading: true,
  error: null,
  data: {
    banners: null,
    categories: null,
    services: null,
    articles: null,
  },
};

const useHomeDataFetch = (): useHomeDataFetchReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchDatas = async (): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });

      const [banners, categories, services, articles] = await Promise.all([
        getBanners(),
        getCategories(),
        getServices({ limit: 5 }),
        getArticles({ limit: 5 }),
      ]);

      dispatch({
        type: SET_DATA,
        payload: { banners, categories, services, articles },
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchDatas };
};

export default useHomeDataFetch;
