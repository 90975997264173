import React, { FC } from 'react';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';


type Props = {
    content: string 
}
export const RenderHtmlComponent : FC<Props> = ({content}) => {
  const { width } = useWindowDimensions();
  return (
    <RenderHtml
      contentWidth={width}
      source={{
        html: content
      }}
      
    />
  );
}