import React, { FC, useEffect } from "react";
import { StyleSheet, Text, Image, ScrollView } from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { BackButton } from "../../components/UI/BackButton";

import { Services } from "./components/Services";

import { SubCategories } from "./components/SubCategories";
import { Blogs } from "../blog/components/BlogsSection";
import useCategoryPageDetails from "../../hooks/useCategoryPageDetails";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { imageHostLink } from "../../constants/apiLinks";
import { COLORS } from "../../constants/colors";

type Props = {
  route: any;
  navigation: StackNavigationProp<RootStackParamList, "Category">;
};

export const CategoryScreen: FC<Props> = ({ route, navigation }) => {
  const categoryId = route.params.categoryId;

  const { data, loading, fetchDatas } = useCategoryPageDetails(categoryId);

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }
  return (
    <ScrollView style={styles.container}>
      <BackButton />
      <Image
        style={styles.image}
        resizeMode="contain"
        source={{ uri: `${imageHostLink}${data?.category?.image}` }}
      />

      <Text style={styles.title}>{data?.category?.name}</Text>
      <SubCategories data={data?.category?.SubCategory} />
      <Services data={data?.services} />
      <Blogs data={data?.articles} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  image: {
    width: "100%",
    height: 250,
    backgroundColor: COLORS.primaryColor,
  },

  title: {
  
    fontSize: 13,
  
    fontWeight: "900",
    margin: 10,
  
   
  },
});
