export const COLORS = {
  defaultColor: "#fff",
  activeBgColor: "3c0a6b",
  rowColor: "#77c4ff",
  secondaryBgColor: "#b9c4cd",
  danger: "red",
  success: "green",
  black: "#000",
  headerBgColor: "#2B1E17",
  textPrimaryColor: "#664D31",
  primaryColor: "#FFE5CF",
  primaryLightColor: "#FFF8F3",
  grayColor: "#EAEAEA",
  lightGrayColor: '#F8F8F8'
};
