import { View, StyleSheet, Text, FlatList } from "react-native";

import React from "react";

import { Divider } from "react-native-elements";
import { COLORS } from "../../../constants/colors";
import { ServiceDescription as ServiceDescriptionType } from "../../../types";
import { ServiceDescriptionItem } from "./ServiceDescriptionItem";

type Props = {
  data: ServiceDescriptionType[] | null;
};
export const ServiceDescription: React.FC<Props> = ({ data }) => {
  
  
  return (
    <>
      <FlatList
        data={data}
        style={styles.flat}
        keyExtractor={(_, index) => "package-key" + index}
        renderItem={({ item }) => <ServiceDescriptionItem item={item} />}
      />
      <View style={[styles.totalContainer]}>
        <Text style={styles.total}>Total</Text>
        <Text style={styles.totalPrice}>{`${20000} Ks`}</Text>
      </View>

      <Divider />
    </>
  );
};

const styles = StyleSheet.create({
  flat: {
    flex: 1,
  },
  item: {
    marginBottom: 10,
  },
  valueContainer: {
    backgroundColor: COLORS.lightGrayColor,
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  value: {
    fontSize: 10,
  },
  totalContainer: {
    paddingHorizontal: 7,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  total: {
    textTransform: "uppercase",
    fontWeight: "900",
    fontSize: 12,
    flex: 1,
  },
  totalPrice: {
    fontWeight: "900",
    fontSize: 12,
    flex: 1,
    textAlign: "right",
  },
  btnContainer: {
    marginVertical: 25,
    alignItems: "center",
  },
  button: {
    width: "70%",
    paddingVertical: 7,
    fontSize: 12,
  },
  btnText: {
    fontSize: 12,
  },
});
