import React, { FC, useEffect } from "react";
import { createContext, useState } from "react";
import { getCache } from "../utils/cache";
import * as AuthService from '../services/authService'
export type User = {
  id: number;
  createdAt: Date;
  name: string;
  password: string;
  email: string;
  total_point: number
};

export type AuthContextType = {
  token: null | string;
  user: User | null;
  isAuthenticated: boolean;
  checkLogin: () => Promise<void>;
  clearState: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  token: null,
  user: null,
  isAuthenticated: false,
  checkLogin: async () => { },
  clearState: () => { },
});

type Props = {
  children: React.ReactNode;
};

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [authToken, setAuthToken] = useState<null | string>(null);
  const [user, setUser] = useState<User | null>(null);

  const checkLogin = async () => {
    const user = (await getCache("user")) || null;
    const access_token = (await getCache("access_token")) || null;



    if(user){

      const userObj=JSON.parse(user);
     
      // @ts-expect-error
      const data = await AuthService.userByID(userObj.id)
      if(data) {
        setUser(data);
      } else {
        setUser(null)
      }
    }
    setAuthToken(access_token);

    []

  };

  const clearState = () => {
    setAuthToken(null);
    setUser(null)
  }



  const value = {
    token: authToken,
    user: user,
    isAuthenticated: !!authToken,
    checkLogin: checkLogin,
    clearState: clearState,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
