import {
  Pressable,
  StyleSheet,
  Text,
  View,
  StyleProp,
  ViewStyle,
} from "react-native";
import React, { FC } from "react";
import { COLORS } from "../../../constants/colors";

type Props = {
  title: string;
  onPress: () => any;
  customStyle?: StyleProp<ViewStyle>;
  customTextStyle?: StyleProp<ViewStyle>;
};

export const SubmitButton: FC<Props> = ({ title, onPress, customStyle, customTextStyle }) => {
  return (
    <Pressable
      style={({ pressed }) => [
        styles.button,
        pressed && styles.pressed,
        customStyle,
      ]}
      onPress={onPress}
    >
      <View>
        <Text style={[styles.buttonText, customTextStyle]}>{title}</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: 14,
    backgroundColor: COLORS.black,
    borderRadius: 30,
    elevation: 2,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    marginTop: 5
  },
  pressed: {
    opacity: 0.7,
  },
  buttonText: {
    textAlign: "center",
    color: COLORS.defaultColor,
    fontSize: 14,
    fontWeight: "bold",
  },
});
