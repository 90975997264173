import React, { FC } from "react";
import { View, StyleSheet, Text } from "react-native";
import { COLORS } from "../../../constants/colors";
import { ServiceDescription } from "../../../types";

type Props = {
  item: ServiceDescription | null;
};
export const ServiceDescriptionItem: FC<Props> = ({ item }) => {
  return (
    <View style={styles.item}>
      <View style={styles.textView}>
        <Text style={styles.title}>{item?.title}</Text>

        <View style={styles.valueContainer}>
          <Text style={styles.value}>{item?.body}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    marginBottom: 10,
  },
  textView: {
    flex: 1,
    justifyContent: "space-around",
    paddingHorizontal: 10,
  },
  title: {
    lineHeight: 18,
    fontSize: 13,
    fontWeight: "900",
    marginVertical: 3,
    flex: 1,
  },
  valueContainer: {
    backgroundColor: COLORS.lightGrayColor,
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
  value: {
    fontSize: 10,
  },
});
