import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { PublicStack } from "./routes/PublicRoutes";

function Navigation() {

  return (
    <NavigationContainer>
      <PublicStack />
    </NavigationContainer>
  );
}

export default Navigation;
