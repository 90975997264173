import { FormData } from "../screens/signup/types";
import { FormData as LoginFormData } from "../screens/login/types";

import { useContext, useReducer } from "react";
import { Action, SET_ERROR, SET_LOADING } from "../types";
import { StackActions, useNavigation } from "@react-navigation/native";
import {
  login as authLogin,
  signup as authSignup,
} from "../services/authService";
import {  removeCache, storeCache } from "../utils/cache";
import { RootStackParamList } from "../App";
import { StackNavigationProp } from "@react-navigation/stack";
import { AuthContext, AuthContextType } from "../store/auth-context";

interface State {
  loading: boolean;
  error: string | null;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

interface UseLoginReturnType extends State {
  login: (data: LoginFormData) => Promise<boolean>;
  signup: (data: FormData) => Promise<boolean>;
  logout: () => Promise<void>;
}

const useLogin = (): UseLoginReturnType => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "Login" | "Home">>();
  const authCtx = useContext<AuthContextType>(AuthContext);

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: null,
  });

  const login = async (data: LoginFormData): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });

      const result = await authLogin(data);

      dispatch({ type: SET_LOADING, payload: false });



      if (!result) {
        dispatch({ type: SET_ERROR, payload: "Login Failed" });
        return false;
      }


      await storeCache("access_token", result?.token);
      await storeCache("user", JSON.stringify(result?.user));
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Login Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  const logout = async () => {
    await removeCache("user");
    await removeCache("access_token");
    authCtx.clearState();
    navigation.dispatch(
      StackActions.replace("Home"))

  };

  const signup = async (data: FormData): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });

      const result = await authSignup(data);

      dispatch({ type: SET_LOADING, payload: false });

      if (result) {
        return true;
      } else {
        dispatch({ type: SET_ERROR, payload: "Signup Failed" });
        return false;
      }
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Signup Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, login, signup, logout };
};

export default useLogin;
