import React, { FC } from "react";
import { View, StyleSheet, Text, ScrollView, Image } from "react-native";
import { BackButton } from "../../../components/UI/BackButton";
import { Article } from "../../../types";
import RenderHtmlComponent from "../../../components/components/RenderHtml";
import { imageHostLink } from "../../../constants/apiLinks";
import { COLORS } from "../../../constants/colors";

type Props = {
  data: Article | null;
};

export const BlogDetails: FC<Props> = ({ data }) => {
  return (
    <ScrollView style={styles.container}>
      <BackButton />
      <Image
        style={styles.image}
        resizeMode="contain"
        source={{ uri: `${imageHostLink}${data?.image}` }}
      />
      <View style={styles.content}>
        <RenderHtmlComponent content={data?.body || ""} />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 22,
  },
  image: {
    width: "100%",
    height: 250,
    backgroundColor: COLORS.primaryColor,
  },
  text: {
    fontSize: 12,
    lineHeight: 15,
  },
});
