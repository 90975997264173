import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../../../App";
import { StackNavigationProp } from "@react-navigation/stack";
import { imageHostLink } from "../../../constants/apiLinks";
import { Image, Text } from "react-native";
import { Service } from "../../../types";
import { Pressable, View, StyleSheet, } from "react-native";
import React, { FC } from "react";
import { COLORS } from "../../../constants/colors";


type ItemProps = {
  item: Service;
  cardWidth?: number
};

export const ServiceCard: FC<ItemProps> = ({ item, cardWidth }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "ServiceDetails">>();
  const navigateToServiceDetails = (id: number) => {
    navigation.navigate("ServiceDetails", { serviceId: id });
  };
  return (
    <Pressable
      style={[styles.card, { width: cardWidth || 180 }]}
      onPress={() => navigateToServiceDetails(item.id)}
    >
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: `${imageHostLink}${item?.image}` }}
      />
      <View style={styles.textView}>
        <Text style={styles.title}>{item.name}</Text>
        {item.myanmarName && (
          <Text style={styles.title}>{item.myanmarName}</Text>
        )}
        {
          item.showPrice && (
            <>
              {item.discountPrice ? (
                <View>
                  <Text style={styles.originalPrice}>{`${item.price} Ks`}</Text>
                  <Text
                    style={styles.discountPrice}
                  >{`${item.discountPrice} Ks`}</Text>
                </View>
              ) : (
                <View>
                  <Text
                    style={{ ...styles.discountPrice, color: "black" }}
                  >{`${item.price} Ks`}</Text>
                </View>
              )}
            </>
          )
        }
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({

  card: {
    borderRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.defaultColor,
    paddingBottom: 10,

    marginRight: 10,
  },
  image: {
    width: "100%",
    height: 150,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  textView: {
    paddingTop: 8,
    flex: 1,
    rowGap: 10,
    paddingHorizontal: 10,
  },
  discountPrice: {
    color: COLORS.danger,
    fontWeight: "900",
    fontSize: 15,
  },
  originalPrice: {
    textDecorationLine: "line-through",
    fontSize: 12,
  },
  title: {
    lineHeight: 15,
    fontSize: 13,
    fontWeight: "800",
  },
});
