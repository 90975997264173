import React, { FC, useContext, useEffect } from "react";
import { View, StyleSheet, FlatList, Text } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { Header } from "../../components/shared/Blogs/Header";
import { PointHistoryRow } from "./components/PointHistoryRow";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import usePointHistory from "../../hooks/usePointHistory";
import { AuthContext, AuthContextType } from "../../store/auth-context";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const PointHistoryScreen: FC<Props> = () => {
  const { pointHistory, loading, fetchDatas } = usePointHistory();
  const authCtx = useContext<AuthContextType>(AuthContext);

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }
  return (
    <View style={styles.container}>
      <Header title="Points History" />
      <View>
        <Text style={styles.point}>Current Point - {authCtx?.user?.total_point}</Text>
      </View>
      <FlatList
        data={pointHistory}
        renderItem={({ item }) => <PointHistoryRow item={item} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.primaryColor,
  },
  point: {
    marginVertical: 10,
    padding: 10,
    fontWeight: '900'
  }
});
