import { useContext, useReducer } from "react";
import { Action, Favorite, SET_ERROR, SET_LOADING, UserFavorites } from "../types";

import { getAll , create, deleteFavorite} from "../services/favoriteService";
import { AuthContext, AuthContextType } from "../store/auth-context";

interface State {
  loading: boolean;
  error: string | null;
  favorites: UserFavorites | null;
}
const SET_DATAS = "SET_DATAS";

export interface SetDatasAction {
  type: typeof SET_DATAS;
  payload: UserFavorites | null;
}

const reducer = (
  state: State,
  action: Action  | SetDatasAction
): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATAS:
      return { ...state, favorites: action.payload };
    default:
      return state;
  }
};

interface useFavoriteReturnType extends State {
  fetchDatas: (userId: number) => void;
  createData: (data: Favorite) => Promise<boolean>;
  deleteData: (id: number) => Promise<boolean>;
}

const initialState = {
  loading: false,
  error: null,
  favorites: null,
};

const useFavorite = (): useFavoriteReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authCtx = useContext<AuthContextType>(AuthContext);
  const fetchDatas = async (userId: number): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const favorites = await getAll(userId);

  
      

      dispatch({
        type: SET_DATAS,
        payload: favorites,
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  const createData = async (data: Favorite): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      await create(data,authCtx?.token);

      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  const deleteData = async (id: number): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      await deleteFavorite(id);

      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchDatas , createData, deleteData};
};

export default useFavorite;
