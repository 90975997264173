import React, {
  useEffect,
  FC,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { View, StyleSheet, Dimensions, FlatList, Animated } from "react-native";
import Slide from "./Banner";
import { COLORS } from "../../../constants/colors";
import { Banner } from "../../../types";

const { width } = Dimensions.get("window");

type Props = {
  data: Banner[] | null | undefined;
};
const BannerContainer: FC<Props> = ({ data }) => {
  const flatListRef = useRef<FlatList | null>(null);

  const scrollX = new Animated.Value(0);
  let position = Animated.divide(scrollX, width);

  const infiniteScroll = useCallback((dataList: Banner[]) => {
    const numberOfData = dataList.length;
    let scrollValue = 0,
      scrolled = 0;

    setInterval(function () {
      scrolled++;
      if (scrolled < numberOfData) scrollValue = scrollValue + width;
      else {
        scrollValue = 0;
        scrolled = 0;
      }

      flatListRef.current?.scrollToOffset({
        animated: true,
        offset: scrollValue,
      });
    }, 3000);
  }, []);

  useEffect(() => {
    if (data) infiniteScroll(data);
  });

  if (data && data?.length) {
    return (
      <View>
        <FlatList
          data={data}
          ref={flatListRef}
          keyExtractor={(item, index) => "key" + index}
          horizontal
          pagingEnabled
          scrollEnabled
          snapToAlignment="center"
          scrollEventThrottle={16}
          decelerationRate={"fast"}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => {
            return <Slide item={item} />;
          }}
          onScroll={Animated.event([
            { nativeEvent: { contentOffset: { x: scrollX } } },
          ])}
        />

        <View style={styles.dotView}>
          {data?.map((_, i) => {
            let opacity = position.interpolate({
              inputRange: [i - 1, i, i + 1],
              outputRange: [0.3, 1, 0.3],
              extrapolate: "clamp",
            });
            return (
              <Animated.View
                key={i}
                style={{
                  opacity,
                  height: 8,
                  width: 8,
                  backgroundColor: COLORS.defaultColor,
                  margin: 8,
                  borderRadius: 5,
                  borderColor: COLORS.defaultColor,
                  borderWidth: 2,
                }}
              />
            );
          })}
        </View>
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  dotView: {
    flexDirection: "row",
    justifyContent: "center",
    position: "absolute",
    bottom: 20,
    alignItems: "center",
    width: "100%",
  },
});

export default BannerContainer;
