import { getData } from "./apiService";

export async function getAll() {
  try {
    const response = await getData("subcategory");
    return response;
  } catch (e) {
    return false;
  }
}
export async function getEach(id: number) {
  try {
    let response = await getData(`subcategory/${id}`);
    return response;
  } catch (e) {
    return false;
  }
}
