import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { ComponentType, ReactElement } from "react";
import { AntDesign, Ionicons, Feather } from "@expo/vector-icons";
import { HomeScreen } from "../../screens/home";
import { ProfileScreen } from "../../screens/profile";
import { MenuScreen } from "../../screens/menu";

import { NotificationScreen } from "../../screens/notification";
import { COLORS } from "../../constants/colors";
import { View, StyleSheet, StatusBar, Platform } from "react-native";
import { FavoriteScreen } from "../../screens/favorites";

const BottomTab = createBottomTabNavigator();

type ScreenComponent = ComponentType<any>;

const withWrapper = (Component: ScreenComponent) => {
  return (props: any) => {
    return (
      <View style={styles.wrapperContainer}>
        <Component {...props} />
      </View>
    );
  };
};

const tabScreens = [
  {
    name: "Home",
    component: withWrapper(HomeScreen),
    icon: (props: any) => <AntDesign name="home" {...props} />,
    headerShown: false,
  },
  {
    name: "Menu",
    component: withWrapper(MenuScreen),
    icon: (props: any) => <AntDesign name="appstore-o" {...props} />,
    headerShown: false,
  },
  {
    name: "Favorites",
    component: withWrapper(FavoriteScreen),
    icon: (props: any) => <Feather name="heart" {...props} />,
    headerShown: true,
  },
  {
    name: "Notification",
    component: withWrapper(NotificationScreen),
    icon: (props: any) => <AntDesign name="bells" {...props} />,
    headerShown: false,
  },
  {
    name: "Profile",
    component: withWrapper(ProfileScreen),
    icon: (props: any) => <Ionicons name="ios-person-outline" {...props} />,
    headerShown: false,
  },
];

export default function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      screenOptions={{
        headerTintColor: "black",
        tabBarActiveTintColor: COLORS.activeBgColor,
        headerTitleAlign: "center",
        headerTitleStyle: {
          fontSize: 15,
          textTransform: "uppercase",
        },
        headerStyle: {
          backgroundColor: COLORS.primaryLightColor,
          elevation: 0,
          
        },
      }}
    >
      {tabScreens.map((tab, index) => (
        <BottomTab.Screen
          key={index}
          name={tab.name}
          component={tab.component}
          options={{
            tabBarIcon: tab.icon,
            headerShown: tab.headerShown,
          }}
        />
      ))}
    </BottomTab.Navigator>
  );
}

const styles = StyleSheet.create({
  wrapperContainer: {
    marginTop: Platform.OS === "ios" ? 30 + (StatusBar.currentHeight || 0) : 0,
    flex: 1,
    backgroundColor: COLORS.defaultColor,
  },
});
