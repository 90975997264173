import React, { FC, useEffect, useState } from "react";
import { View, StyleSheet, Text, ScrollView, FlatList } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import PromoSingleBanner from "../home/components/PromoSingleBanner";
import { Services } from "../../components/shared/Services/Services";
import { Icon } from "react-native-elements";
import { TextBox } from "../../components/components/TextBox";

import { CategoryGrid } from "./components/CategoryGrid";
import useMenuDataFetch from "../../hooks/useMenuDataFetch";
import LoadingOverlay from "../../components/UI/LoadingOverlay";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const MenuScreen: FC<Props> = ({ navigation }) => {
  const { data, loading, fetchDatas } = useMenuDataFetch();
  const [searchKeyWord, setSearchKeyWord] = useState("");

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

  const handleSearch = () => {
    if (searchKeyWord) {
      navigation.navigate("SearchedServices", { search: searchKeyWord });
    }
  };
  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.textBoxContainerStyle}>
        <TextBox
          placeholder="Search here"
          inputContainerStyle={styles.textBox}
          leftIcon={<Icon name="search" type="font-awesome" />}
          onSubmitEditing={handleSearch}
         
          onChange={(text) => {
            console.log(text);
            setSearchKeyWord(text as string);
          }}
        />
      </View>
      <PromoSingleBanner item={data?.banners ? data?.banners[0] : null} />
      <CategoryGrid categories={data?.categories} />

      <Services data={data?.services} onRoutePress={() => {}} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 10,
    marginBottom: 30,
  },
  textBoxContainerStyle: {
    flex: 1,

    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.primaryColor,
    marginBottom: 20,
    paddingHorizontal: 10,
  },

  textBox: {
    height: 40,
    marginTop: 20,
    backgroundColor: "white",
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 3,
    outline: "none",
    elevation: 10,
    borderWidth: 1,
    borderColor: COLORS.defaultColor,
    gap: 10,
  },
});
