import { Avatar, Divider } from "react-native-elements";
import React, { FC } from "react";
import { StyleSheet, Text, View, Image, Pressable } from "react-native";
import { COLORS } from "../../../constants/colors";
import { Service } from "../../../types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../App";
import { imageHostLink } from "../../../constants/apiLinks";

type ItemProps = {
  item: Service;
};

export const FavoriteItem: FC<ItemProps> = ({ item }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "ServiceDetails">>();
  const navigateToServiceDetails = (id: number) => {
    navigation.navigate("ServiceDetails", { serviceId: id });
  };

  return (
    <Pressable
      style={styles.container}
      onPress={() => navigateToServiceDetails(item.id)}
    >
      <View style={styles.blogItem}>
        <Image
          style={styles.image}
          resizeMode="cover"
          source={{ uri: `${imageHostLink}${item?.image}` }}
        />
        <View style={styles.content}>
          <Text style={styles.title}>{item.name}</Text>

          <Text style={styles.description}>{item.name}</Text>

          <View style={styles.priceWrapper}>
            <Text style={styles.originalPrice}>{`${item.price} Ks`}</Text>
            <Text style={styles.discountPrice}>{`${item.price} Ks`}</Text>
          </View>
        </View>
      </View>
      <Divider />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
    gap: 20,
  },
  image: {
    width: "50%",
    height: 100,
    borderRadius: 5,
    elevation: 10,
  },
  blogItem: { flexDirection: "row", gap: 8, flex: 1 },
  content: {
    flex: 1,
    gap: 5,
  },
  title: {
    fontWeight: "900",
    fontSize: 10,
    lineHeight: 13,
  },
  description: {
    fontSize: 9,
    marginTop: 5,
    fontWeight: "600",
    lineHeight: 12,
  },
  priceWrapper: {
    flexDirection: "row",
    alignItems: "flex-end",
    gap: 5,
  },
  discountPrice: {
    color: COLORS.danger,
    fontWeight: "900",
    fontSize: 13,
  },
  originalPrice: {
    textDecorationLine: "line-through",
    fontSize: 10,
  },
});
