import { Favorite, HttpMethod } from "../types";
import { getData, sendData } from "./apiService";

export async function getAll(userId: number) {
  try {
    const response = await getData(`favorite/user/${userId}`);
   
    return response;
  } catch (e) {
    return false;
  }
}
export async function create(data: Favorite,token:string) {
  try {
    let response = await sendData(`favorite`, data, HttpMethod.post,token);
    return response;
  } catch (e) {
    return false;
  }
}

export async function deleteFavorite(id: number) {
  try {
    const response = await sendData(`favorite/${id}`, null, HttpMethod?.delete);
    return response;
  } catch (e) {
    return false;
  }
}