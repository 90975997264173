import React, { FC, useContext } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { COLORS } from "../../../constants/colors";
import { Image } from "react-native";
import { PrepaidCard } from "../../../types";
import { AuthContext, AuthContextType } from "../../../store/auth-context";
import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../../../App";
import { StackNavigationProp } from "@react-navigation/stack";

export const MemberCard: FC<{ prepaidCard: PrepaidCard, cardWidth?: number }> = ({ prepaidCard, cardWidth }) => {

  const authCtx = useContext<AuthContextType>(AuthContext);
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "PrepaidCard">>();

  return (
    <Pressable
      onPress={() => {
        navigation.navigate("PrepaidCard", { id: prepaidCard.prepaidCard.id });
      }}

    >
      <View>
        <View style={{ ...styles.card, backgroundColor: prepaidCard.prepaidCard.color, width: cardWidth ? `${cardWidth}%` : '80%' }}>
          <View style={styles.row}>
            <View style={styles.row}>
              <Text style={[{ ...styles.bigText, color: prepaidCard.prepaidCard.textColor }, styles.flex]}>{prepaidCard.prepaidCard.name}</Text>
            </View>
            <View style={styles.iconWrapper}>
              <FontAwesome5
                name="crown"
                size={14}
                color={prepaidCard.prepaidCard.textColor}
              />
            </View>
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.bigText, color: prepaidCard.prepaidCard.textColor }}>{prepaidCard.card_no}</Text>
          </View>
          <View style={[styles.row, { justifyContent: "flex-start" }]}>
            <View style={styles.flex}>
              <Text style={{ ...styles.smallText, color: prepaidCard.prepaidCard.textColor }}>Bal</Text>
            </View>
            <View style={{ flex: 7 }}>
              <Text style={{ ...styles.smallText, color: prepaidCard.prepaidCard.textColor }}>{prepaidCard.balance}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.flex}>
              <Text style={{ ...styles.smallText, color: prepaidCard.prepaidCard.color }}>{authCtx.user?.name}</Text>
            </View>
            <View style={styles.imageWrapper}>
              <Image source={require("../../../assets/images/logo.png")} />
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardWrapper: {
    width: "100%",
    height: "20%",
    position: "relative",
    alignItems: "center",
  },
  card: {
    backgroundColor: "white",
    height: 160,
    borderRadius: 20,
    justifyContent: "space-evenly",
    alignItems: "center",
    shadowColor: '#e0dede',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.5,
    // shadowRadius: 3,
    elevation: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  bigText: {
    color: COLORS.textPrimaryColor,
    fontWeight: "900",
  },
  smallText: {
    fontWeight: "300",
    color: COLORS.textPrimaryColor,
    fontSize: 14,
  },
  iconWrapper: {
    backgroundColor: "#E8E8E8",
    padding: 5,
    borderRadius: 50,
  },
  imageWrapper: { flex: 1, alignItems: "flex-end" },
  flex: { flex: 1 },
});
