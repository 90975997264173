import { getData } from "./apiService";


export async function getAll() {
  try {
    const response = await getData("banner");
    return response;
  } catch (e) {
    return false;
  }
}
