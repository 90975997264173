import React, { useContext, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import useFavorite from "../../../hooks/useFavorite";
import { Text } from "react-native";
import { AuthContext, AuthContextType } from "../../../store/auth-context";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../App";
import { getCache } from "../../../utils/cache";

type Props = {
  isFavorite: boolean;
  service_id: number;
  navigation: StackNavigationProp<RootStackParamList, 'ServiceDetails'>;
  favoriteId?: number;

};
export const FavoriteIcon: React.FC<Props> = ({ isFavorite, service_id , favoriteId, navigation}) => {
  const authCtx = useContext<AuthContextType>(AuthContext);
  const [favorite , setIsFavorite] = useState(isFavorite);
  const { createData, deleteData, loading } = useFavorite();
  const onCreateFavorite = async () => {
    if(!authCtx?.user?.id && !await getCache('access_token')) {
      navigation.navigate("Login")
    }
    if (service_id && authCtx?.user?.id) {
      const formData = { service_id, user_id: authCtx?.user?.id };
      const success = await createData(formData);
      if(success) {
        setIsFavorite(true)
      }
    }
  };
  const onDeleteFavorite = async () => {
    if(favoriteId) {
       const sucess = await deleteData(favoriteId)
       if(sucess) {
        setIsFavorite(false)
       }
    }
  };
  if(loading) {
    return <Text>...</Text>
  }
  return favorite ? (
    <AntDesign
      name="star"
      size={24}
      color={"red"}
      onPress={onDeleteFavorite}
    />
  ) : (
    <AntDesign
      name="staro"
      size={24}
      color={"black"}
      onPress={onCreateFavorite}
    />
  );
};
