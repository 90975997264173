import React, { FC } from 'react'
import { View, Text,StyleSheet } from 'react-native';

type Props = {
    label: string;
    value: string;
}

export const Info: FC<Props> = ({ label, value }) => {
    return (
        <View style={styles.textView}>
            <Text style={styles.label}>{label}</Text>
            <Text style={styles.value}>{value}</Text>
        </View>
    )
}

export default Info

const styles = StyleSheet.create({
    textView: {
       display:"flex",
       alignItems:'center',
       flexDirection:"row"
    },
    label: {
        fontSize:17,
        width:"50%",
        paddingLeft:"3%",
        fontWeight:"bold"
    },
    value: {
        fontSize:17
    }
});