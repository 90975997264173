import React, { useState, useContext, useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AppLoading from "expo-app-loading";
import AuthContextProvider, {
  AuthContext,
  AuthContextType,
} from "./store/auth-context";
import Navigation from "./navigation";
import { StatusBar } from "react-native";
import Toast from "react-native-toast-message";
import { usePushNotifications } from "./hooks/usePushNotification";

export type RootStackParamList = {
  Home: undefined;
  Signup: undefined;
  Login: undefined;
  Profile: undefined;
  Favorite: undefined;
  ServiceDetails: {serviceId: number};
  SearchedServices: {search: string};
  Blog: { articleId: number };
  Category: { categoryId: number };
  SubCategoryDetails: { subcategoryId: number };
  PrepaidCard: { id: number };
  PointHistory: undefined;
};

function Root() {
  const [isTryingLogin, setIsTryingLogin] = useState(true);

  const authCtx = useContext<AuthContextType>(AuthContext);
  const { notification } = usePushNotifications();
  const data = JSON.stringify(notification, undefined, 2);

  useEffect(() => {
    async function loadUser() {
      await authCtx.checkLogin();
    
      setIsTryingLogin(false);
    }
    loadUser();
  }, []);

  if (isTryingLogin) {
    return <AppLoading />;
  }

  return <Navigation />;
}
export default function App() {
  return (
    <AuthContextProvider>
      <StatusBar hidden={true} />
      <SafeAreaProvider>
      <Toast />
        <Root />
      </SafeAreaProvider>
    </AuthContextProvider>
  );
}
