import React, { FC } from "react";
import { View, StyleSheet, Text, Image, StyleProp, ViewStyle } from "react-native";
import { COLORS } from "../../../constants/colors";
import { SubmitButton } from "../../../components/UI/SubmitButton";
import { Banner } from "../../../types";
import { imageHostLink } from "../../../constants/apiLinks";


type Props = {
  item: Banner | null;
};
const PromoSingleBanner: FC<Props> = ({ item }) => {
  const handlePress = () => {};
  return (
    <View style={styles.card}>
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: `${imageHostLink}${item?.image}` }} 
        
      />
      <View style={styles.textView}>
        <Image
          style={styles.logo}
          resizeMode="contain"
          source={require("../../../assets/images/logo.png")}
        />
        <Text style={styles.title}>{item?.title}</Text>
        <Text style={styles.subtitle}>{item?.subtitle}</Text>

        <SubmitButton
          title="Try It Out"
          onPress={handlePress}
          customStyle={styles.button}
          customTextStyle={styles.btnText  as StyleProp<ViewStyle>}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    height: 150,
    backgroundColor: COLORS.primaryColor,
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    borderRadius: 10,
    elevation: 2,
    marginVertical: 15,
  },
  textView: {
    position: "absolute",
    bottom: 15,
    marginLeft: 10,
    left: 5,
    justifyContent: "center",
    width: "70%",
    gap: 5,
  },
  image: {
    width: "40%",
    height: 150,
    borderRadius: 10,
    alignSelf: "flex-end",
  },
  logo: {
    marginBottom: 5,
    width: 60,
    height: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: "900",
    lineHeight: 18,
  },
  subtitle: {
    fontSize: 13,
    fontWeight: "700",
    lineHeight: 15,
  },
  button: {
    width: "80%",
    paddingVertical: 3,
    fontSize: 12,
  },
  btnText: {
    fontSize: 12,
  },
});

export default PromoSingleBanner;
