import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC, useContext } from "react";
import { AuthContext, AuthContextType } from "../store/auth-context";
import { RootStackParamList } from "../App";
import { getCache } from "../utils/cache";

type Props = {
  children: React.ReactNode;
  navigation: StackNavigationProp<RootStackParamList>;
};
export default function AuthLayout({ children, navigation }: Props) {
  const authCtx = useContext<AuthContextType>(AuthContext);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      await authCtx.checkLogin();
      const access_token = (await getCache("access_token")) || null;
     
      if (!access_token) navigation.navigate("Login")
        
    });

    return unsubscribe;
  }, [navigation]);

  return <>{children}</>;
}
