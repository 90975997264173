import React from "react";
import {
  Text,
  Pressable,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";

type Props = {
  title: string;
  onPress: () => any;
  customTextStyle?: StyleProp<ViewStyle>;
  iconName?: keyof typeof Ionicons.glyphMap;
};
export const RouteButton: React.FC<Props> = ({
  title,
  onPress,
  customTextStyle,
  iconName,
}) => {
  return (
    <Pressable style={styles.container} onPress={()=>onPress()}>
      <Text style={[styles.title, customTextStyle]}>{title}</Text>

      {iconName ? (
        <Ionicons name={iconName} size={24} color="black" />
      ) : (
        <MaterialCommunityIcons
          name="arrow-right-bold-box-outline"
          size={24}
          color="black"
        />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: 5,
    marginVertical: 10,
    alignItems: 'center'
  },
  title: {
    textTransform: "uppercase",
  },
});
