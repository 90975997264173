import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import React, { FC } from 'react';
import { COLORS } from '../../../constants/colors';

type Props = {
    message: string;
}
const LoadingOverlay : FC<Props> = ({ message }) => {
  return (
    <View style={styles.rootContainer}>
      <Text style={styles.message}>{message}</Text>
      <ActivityIndicator size="large" />
    </View>
  );
}

export default LoadingOverlay;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    backgroundColor: COLORS.primaryColor
  },
  message: {
    fontSize: 16,
    marginBottom: 12,
  },
});
