import React, { FC } from "react";
import { View, StyleSheet, FlatList, Pressable, Image } from "react-native";
import { COLORS } from "../../../constants/colors";
import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../../../App";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteButton } from "../../home/components/RouteButton";
import { Service } from "../../../types";
import { imageHostLink } from "../../../constants/apiLinks";

type Props = {
  data: Service[] | null | undefined;
};

export const Services: FC<Props> = ({ data }) => {
  return (
    <View style={styles.container}>
      <RouteButton title="Services" onPress={() => {}} />
      <FlatList
        numColumns={2}
        data={data}
        keyExtractor={(item, index) => "service-key" + index}
        style={styles.flat}
        renderItem={({ item }) => {
          return <ServiceCard item={item} />;
        }}
      />
    </View>
  );
};

type ServiceItemProps = {
  item: Service;
};
const ServiceCard: FC<ServiceItemProps> = ({ item }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "ServiceDetails">>();
  const navigateToServiceDetails = (id: number) => {
    navigation.navigate("ServiceDetails", { serviceId: id });
  };
  return (
    <View style={{paddingVertical:4}}>
    <Pressable
      style={styles.card}
      onPress={() => navigateToServiceDetails(item.id)}
    >
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: `${imageHostLink}${item?.image}` }} 
      />
    </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flat: {
    overflow: "visible",
  },

  card: {
    borderRadius: 10,
    elevation: 2,
    backgroundColor: COLORS.defaultColor,
    height: 150,
    width: 180,
    marginRight: 10,
  },
  image: {
    width: "100%",
    height: 150,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  textView: {
    flex: 1,
    justifyContent: "space-around",
    paddingHorizontal: 10,
  },
  discountPrice: {
    color: COLORS.danger,
    fontWeight: "900",
    fontSize: 15,
  },
  originalPrice: {
    textDecorationLine: "line-through",
    fontSize: 12,
  },
  title: {
    lineHeight: 15,
    fontSize: 11,
    fontWeight: "800",
  },
});
