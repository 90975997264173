import React, { FC } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  Image,
  StyleProp,
  ViewStyle,
  Pressable,
} from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../App";
import { useNavigation } from "@react-navigation/native";
import { Article } from "../../../types";
import { RouteButton } from "../../home/components/RouteButton";
import { imageHostLink } from "../../../constants/apiLinks";
import { BackButton } from "../../../components/UI/BackButton";

type Props = {
  data: Article[] | null | undefined;
  children?: React.ReactNode;
  onPress?: () => void;
  allList?: boolean;
};

export const Blogs: FC<Props> = ({ data, allList = false, onPress }) => {
  return (
    <View style={styles.container}>
      {
        allList ? <View style={{paddingVertical:10,paddingLeft:"15%",alignItems:"center",justifyContent:"center"}}>
          <BackButton top={5} />
          <Text style={styles.warningText}>
            သတိပြုရန်နှင့်‌ ဆောင်ရန်/ရှောင်ရန်များ
          </Text>
        </View> : <View>
          <Text style={styles.warningText}>
            သတိပြုရန်နှင့်‌ ဆောင်ရန်/ရှောင်ရန်များ
          </Text>
        </View>
      }

      <FlatList
        data={data}
        renderItem={({ item }) => <BlogItem item={item} />}
      />
      {!allList && <View style={styles.read_more_container}>
        <RouteButton
          title="Read More"
          iconName={"arrow-forward-circle-outline"}
          onPress={onPress ? onPress : () => { }}
          customTextStyle={styles.read_more as StyleProp<ViewStyle>}
        />
      </View>}
    </View>
  );
};

type ItemProps = {
  item: Article;
};

export const BlogItem: React.FC<ItemProps> = ({ item }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "Blog">>();
  const navigateToBlog = (id: number) => {
    navigation.navigate("Blog", { articleId: id });
  };
  return (
    <Pressable style={styles.blogItem} onPress={() => navigateToBlog(item.id)}>
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: `${imageHostLink}${item?.image}` }}

      />
      <View style={styles.content}>
        <Text style={styles.title}>{item.title}</Text>

        <Text style={styles.description}>{item?.description}</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
    gap: 20,
  },
  image: {
    width: "35%",
    height: 100,
  },
  blogItem: { flexDirection: "row", gap: 8,paddingBottom:8 },
  content: {
    flex: 1,
  },
  title: {
    fontWeight: "900",
    fontSize: 14,
    lineHeight: 13,
  },
  description: {
    fontSize: 10,
    marginTop: 5,
    fontWeight: "600",
    lineHeight: 12,
  },
  warningText: {
    fontWeight: "900",
    fontSize: 14,
  },
  read_more_container: {
    marginVertical: 5,
    alignItems: "center",
    flex: 1,
  },
  read_more: {
    fontSize: 10,
    // fontWeight: "900",
  },
});
