import { getData, sendData } from "./apiService";
import { FormData } from "../screens/signup/types";
import { FormData as LoginFormData } from "../screens/login/types";

export async function login(values: LoginFormData) {
  try {
    const response = await sendData("user/login", values);
    
    
    return response;
  } catch (e) {
    return false;
  }
}

export async function signup(values: FormData) {
  try {
    const response = await sendData("user/signup", values);
    return response;
  } catch (e) {
    return false;
  }
}

export async function user() {
  try {
    const response = await getData("user");
    return response;
  } catch (e) {
    return false;
  }
}

export async function userByID(id:any) {
  try {
    const response = await getData("user/"+id);
    return response;
  } catch (e) {
    return false;
  }
}