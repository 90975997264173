export enum HttpMethod {
  get = "get",
  post = "post",
  put = "put",
  delete = "delete",
}

export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string | null;
}

export type Action = SetLoadingAction | SetErrorAction;

export type Banner = {
  id: number;
  createdAt: Date;
  title: string;
  subtitle: string;
  image: string;
};

export type Article = {
  id: number;
  createdAt: Date;
  title: string;
  body: string;
  image: string;
  description: string;
};

export type Category = {
  id: number;
  createdAt: Date;
  name: string;
  caption: string;
  image: string;
};

export type Notification = {
  id: number;
  createdAt: Date;
  title: string;
  image: string;
  description: string;
};
export interface PointHistory {
  id: number;
  createdAt: Date;
  point: number;
  type: string;
};
export type Service = {
  id: number;
  createdAt: Date;
  name: string;
  myanmarName:string;
  image: string;
  price: number;
  discountPrice:number;
  showPrice: boolean;
  category_id: number;
  sub_category_id: number | null;
  descriptions: string;
};

export type SubCategory = {
  id: number;
  createdAt: Date;
  name: string;
  category_id: number;
  image: string;
};

export type User = {
  id: number;
  createdAt: Date;
  name: string;
  password: string;
  email: string;
};

export type ServiceDescription = {
  body: string;
  title: string;
};

export type Favorite = {
  user_id: number;
  service_id: number;
};

export type UserFavorites = Record<string, Record<string, Array<Service>>>;
export type UserFavoriteProducts = {products: Array<Service>};
export type UserPrepaidCardHistory={
  amount:number;
  createdAt:Date;
}

export type PrepaidCard = {
  balance: number;
  card_no:string;
  prepaidCard: {
    id: number,
    name: string,
    balance: number,
    code: string,
    color: string
    textColor:string;
  },
  UserPrepaidCardHistory:UserPrepaidCardHistory[]

}
