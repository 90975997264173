import { sendData } from "./apiService";

export async function saveDeviceToken(token:string) {
    try {
      const response = await sendData("device-token", {token:token});
      
      
      return response;
    } catch (e) {
      return false;
    }
  }