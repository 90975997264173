import React, { FC } from "react";
import {
  View,
  StyleSheet,
  FlatList,
} from "react-native";
import { RouteButton } from "../../../screens/home/components/RouteButton";

import { Service } from "../../../types";
import { ServiceCard } from "./ServiceCard";

type Props = {
  data: Service[] | null | undefined;
  onRoutePress: () => void;
};

export const Services: FC<Props> = ({ data, onRoutePress }) => {
  return (
    <View style={styles.container}>
      <RouteButton
        title="Services"
        onPress={onRoutePress}
      />
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        data={data}
        keyExtractor={(item, index) => "service-key" + index}
        style={styles.flat}
        renderItem={({ item }) => {
          return <ServiceCard item={item} />;
        }}
      />
    </View>
  );
};



const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flat: {
    overflow: "visible",
  },
});
