import React, { FC, useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  FlatList,
  Dimensions,
  ActivityIndicator,
  Pressable,
  Text,
} from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { Profile } from "./components/Profile";
import { MemberCard } from "./components/MemberCard";
import { SubmitButton } from "../../components/UI/SubmitButton";
import { Header } from "../../components/shared/Blogs/Header";
import { AuthContext, AuthContextType } from "../../store/auth-context";
import useLogin from "../../hooks/useLogin";
import usePrepaidCard from "../../hooks/usePrepaidCard";
import { getCache } from "../../utils/cache";
import AuthLayout from "../../layouts/AuthLayout";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Profile">;
};

export const ProfileScreen: FC<Props> = ({ navigation }) => {
  const authCtx = useContext<AuthContextType>(AuthContext);
  const { logout } = useLogin();

  const { prepaidCards, loading, fetchDatas } = usePrepaidCard();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      await authCtx.checkLogin();
      const access_token = (await getCache("access_token")) || null;

      if (!access_token) navigation.navigate("Login");

      await loadDatas();
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    loadDatas();
  }, []);

  const navigateToPointHistory = () => {
    if (!authCtx?.user?.id) return;
    navigation.navigate("PointHistory");
  };
  async function loadDatas() {
    const userObject = (await getCache("user")) || null;
    if (userObject) {
      const user = JSON.parse(userObject);
      // @ts-expect-error
     
      fetchDatas(user["id"]);
    }
  }

  const handleLogout = async () => {
    await logout();
  };

  return (
    <AuthLayout navigation={navigation}>
      <View style={styles.container}>
        <Header
          title="Profile"
          backgroundColor={COLORS.headerBgColor}
          titleColor={COLORS.defaultColor}
        />
        <Profile />

        <View style={styles.flatListContainer}>
          {loading ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <ActivityIndicator size="large" />
            </View>
          ) : (
            <FlatList
              ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
              data={prepaidCards}
              renderItem={({ item }) => <MemberCard prepaidCard={item} />}
            />
          )}
        </View>
        <View style={styles.buttonWrapper}>

          <Pressable
            style={({ pressed }) => [styles.pointButton, pressed && styles.pressed]}
            onPress={navigateToPointHistory}
          >
            <View>
              <Text style={styles.buttonText}>
                Total Points - {authCtx?.user?.total_point}
              </Text>
            </View>
          </Pressable>

          <SubmitButton
            title="Logout"
            onPress={handleLogout}
            customStyle={styles.button}
            customTextStyle={styles.btnText as StyleProp<ViewStyle>}
          />
        </View>
      </View>
    </AuthLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flatListContainer: {
    width:"100%",
    position: "absolute",
    top: Dimensions.get("window").height * 0.29,
    left: Dimensions.get("window").width * 0.1,
    height: Dimensions.get("window").height * 0.55,
  },
  header: {
    backgroundColor: COLORS.headerBgColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: Dimensions.get("window").height * 0.29,
    width: "100%",
  },
  title: {
    color: COLORS.defaultColor,
  },
  buttonWrapper: {
    width: "100%",
    alignItems: "center",
    position: "absolute",
    bottom: Dimensions.get("window").height * 0.04,
  },
  button: {
    width: "70%",
    paddingVertical: 7,
    fontSize: 12,
    marginTop: 30,
  },
  btnText: {
    fontSize: 12,
  },

  pointButton: {
    paddingVertical: 10,
    backgroundColor: COLORS.danger,
    borderRadius: 30,
    elevation: 2,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    width: "70%"
  },
  pressed: {
    opacity: 0.7,
  },
  buttonText: {
    textAlign: "center",
    color: COLORS.defaultColor,
    fontSize: 14,
    fontWeight: "bold",
  },
});
