import React, { FC, useEffect, useState } from "react";
import { StyleSheet, ScrollView } from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import Banner from "./components/BannerContainer";
import { Categories } from "./components/Categories";
import { Services } from "../../components/shared/Services/Services";
import PromoSingleBanner from "./components/PromoSingleBanner";

import LoadingOverlay from "../../components/UI/LoadingOverlay";
import useHomeDataFetch from "../../hooks/useHomeDataFetch";
import { Blogs } from "../blog/components/BlogsSection";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const HomeScreen: FC<Props> = ({ navigation }) => {
  const { data, loading, fetchDatas } = useHomeDataFetch();

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

  const goToBlogPage = () => {
    // @ts-expect-error
    navigation.navigate("Blogs");
  };

  const goToMenuPage = () => {
    // @ts-expect-error
   navigation.navigate("Menu");
  };
  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  return (
    <ScrollView style={styles.container}>
      <Banner data={data?.banners} />
      <Categories data={data?.categories} />
      <Services data={data?.services} onRoutePress={goToMenuPage}/>

      <PromoSingleBanner item={data?.banners ? data?.banners[0] : null} />
      <Blogs data={data?.articles} onPress={goToBlogPage} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});