import axios, { type AxiosResponse } from "axios";
import { HttpMethod } from "../types";


const apiEndpoint = 'http://150.95.81.15:4800/api/'



const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export async function getData(url: string, params?: unknown, token?: string) {
  const headers={};
  if (token) {
    // @ts-expect-error
    headers.Authorization = `Bearer ${token}`;
  }



  // axios.defaults.headers.common = headers;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get(`${apiEndpoint}${url}`, { params,headers:headers });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function sendData<T>(
  url: string,
  data?: unknown,
  type: HttpMethod = HttpMethod.post,
  token?: string
): Promise<T | undefined> {
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  axios.defaults.headers.common = headers;

  let response: AxiosResponse<T> | null = null;

  if (type === HttpMethod.post)
    response = await axios.post(`${apiEndpoint}${url}`, data);
  else if (type === HttpMethod.delete)
    response = await axios.delete(`${apiEndpoint}${url}`);
  else if (type === HttpMethod.put)
    response = await axios.put(`${apiEndpoint}${url}`, data);

  // if (response?.status === 200) {
  //   return;
  // }

  return response?.data;
}
