import React, { FC, useCallback, useContext, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  ImageBackground,
  Pressable,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Alert,
} from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { TextBox } from "../../components/components/TextBox";
import { SubmitButton } from "../../components/UI/SubmitButton";
import { useForm, Controller } from "react-hook-form";
import { ObjectSchema, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormData } from "./types";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import useLogin from "../../hooks/useLogin";
import { COLORS } from "../../constants/colors";

const schema: ObjectSchema<FormData> = object().shape({
  name: string().required("Name is required"),
  password: string().required("Password is required"),
  email: string().required("Email is required"),
});

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Signup">;
};

export const SignupScreen: FC<Props> = ({ navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { loading, signup } = useLogin();

  const handleSignin = () => {
    navigation.replace("Login");
  };

  const onSubmit = useCallback(async (data: FormData) => {
    const res = await signup(data);
    if (!res) {
      Alert.alert(
        "Authentication failed!",
        "Could not signup. Your account is already existed or server error"
      );
    }
    Alert.alert(
      "Sign Up  Success",
      "You can login now"
    );
    navigation.replace("Login");
  }, []);

  if (loading) {
    return <LoadingOverlay message="Creating user..." />;
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ImageBackground
        source={require("../../assets/images/Signup.jpg")}
        style={styles.image}
        resizeMode="contain"
      >
        <ScrollView contentContainerStyle={styles.formContainer}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                placeholder="Name"
                {...field}
                errorMessage={errors.name?.message}
                error={errors?.name}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                {...field}
                placeholder="Email/ Phone Number"
                errorMessage={errors.email?.message}
                error={errors?.email}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                {...field}
                placeholder="Password"
                errorMessage={errors.password?.message}
                error={errors?.password}
              />
            )}
          />

          <View style={styles.buttonWrapper}>
            <SubmitButton title="Signup" onPress={handleSubmit(onSubmit)} />
          </View>
          <View style={styles.row}>
            <Text style={styles.textWrapper}>
              <Text style={styles.textStyle}>Already have an account?</Text>
              <Text style={styles.extraBoldTextStyle} onPress={handleSignin}>
                Signin
              </Text>
            </Text>
          </View>
        </ScrollView>
      </ImageBackground>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
   
  },
  formContainer: {
    flex: 1,
    paddingHorizontal: 20,
    gap: 5,
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  image: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    backgroundColor:COLORS.primaryColor
  },
  input: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 30,
    paddingHorizontal: 20,
    paddingVertical: 3,
    outline: "none",
  },
  textWrapper: {
    flexDirection: "row",
    flexGrow: 1,
    textAlign: "center",
  },
  textStyle: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
  },
  extraBoldTextStyle: {
    fontSize: 13,
    fontWeight: "900",
  },
  buttonWrapper: {
    width: "100%",
  },
});
