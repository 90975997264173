import { useReducer } from "react";
import {
  Action,
  Article,
  Category,
  SET_ERROR,
  SET_LOADING,
  Service,
} from "../types";

import { getAll as getArticles } from "../services/articleService";

interface ArticlesData {
  articles: Article[] | null;
}
interface State {
  loading: boolean;
  error: string | null;
  data: ArticlesData | null;
}
const SET_DATA = "SET_DATA";

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: ArticlesData | null;
}

const reducer = (state: State, action: Action | SetDataAction): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

interface useArticlesDataFetchReturnType extends State {
  fetchDatas: () => void;
}

const initialState = {
  loading: true,
  error: null,
  data: {
    articles: null,
  },
};

const useArticlesDataFetch = (): useArticlesDataFetchReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchDatas = async (): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });

      const [ articles] = await Promise.all([
        getArticles({ limit: 100 }),
      ]);

      dispatch({
        type: SET_DATA,
        payload: { articles },
      });
      dispatch({ type: SET_LOADING, payload: false });

      if (result?.error) {
        dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
        return false;
      }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchDatas };
};

export default useArticlesDataFetch;
