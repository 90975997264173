import { useContext, useReducer } from "react";
import { Action, SET_ERROR, SET_LOADING, Service } from "../types";

import {
  getAll,
  getEach,
  getEachByToken,
} from "../services/packageServiceService";
import { AuthContext, AuthContextType } from "../store/auth-context";

interface State {
  loading: boolean;
  error: string | null;
  services: Service[] | null;
  service: (Service & { isFavorite: boolean; isFavoriteId: number }) | null;
}
const SET_DATAS = "SET_DATAS";
const SET_DATA = "SET_DATA";

export interface SetDatasAction {
  type: typeof SET_DATAS;
  payload: Service[] | null;
}
export interface SetDataAction {
  type: typeof SET_DATA;
  payload: (Service & { isFavorite: boolean; isFavoriteId: number }) | null;
}

const reducer = (
  state: State,
  action: Action | SetDataAction | SetDatasAction
): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATA:
      return { ...state, service: action.payload };
    case SET_DATAS:
      return { ...state, services: action.payload };
    default:
      return state;
  }
};

interface useServiceReturnType extends State {
  fetchData: (id: number) => void;
  fetchDatas: (search?: string) => void;
}

const initialState = {
  loading: true,
  error: null,
  service: null,
  services: null,
};

const useService = (): useServiceReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authCtx = useContext<AuthContextType>(AuthContext);
  const fetchDatas = async (search?: string): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const services = await getAll({ limit: 100, search });

      dispatch({
        type: SET_DATAS,
        payload: services,
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  const fetchData = async (id: number): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const service = authCtx.user
        ? await getEachByToken(id, authCtx.token as string)
        : await getEach(id);

      dispatch({
        type: SET_DATA,
        payload: service,
      });
      dispatch({ type: SET_LOADING, payload: false });
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchData, fetchDatas };
};

export default useService;
