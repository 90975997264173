import React, { FC } from "react";
import { View, StyleSheet, Text, Image, Pressable, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../App";
import { imageHostLink } from "../../../constants/apiLinks";
import { COLORS } from "../../../constants/colors";

type Props = {
  categories: any;
};
export const CategoryGrid: React.FC<Props> = ({ categories }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "Category">>();
  const navigateToCategory = (index: number) => {
    navigation.navigate("Category", { categoryId: categories[index]?.id });
  };

  if (Platform.OS==="web") {
    return (<></>)
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Category</Text>
      <View style={styles.imageContainer}>
        <View style={styles.firstImageRowWrapper}>
          <Pressable
            style={styles.firstRowImage}
            onPress={() => navigateToCategory(0)}
          >
            <Image
              style={styles.image}
              source={{ uri: `${imageHostLink}${categories[0]?.image}` }}

              resizeMode="cover"
            />
            <View style={styles.categoryTextContainer}>
              <Text style={styles.categoryText}>{categories[0]?.name}</Text>
            </View>
          </Pressable>

          <View style={styles.firstRowImage}>
            <Pressable
              style={{ flex: 1 }}
              onPress={() => navigateToCategory(1)}
            >
              <Image
                style={styles.image}
                source={{ uri: `${imageHostLink}${categories[1]?.image}` }}

                resizeMode="cover"
              />
              <View style={styles.categoryTextContainer}>
                <Text style={styles.categoryText}>{categories[1]?.name}</Text>
              </View>
            </Pressable>
            <Pressable
              style={{ flex: 1 }}
              onPress={() => navigateToCategory(2)}
            >
              <Image
                style={styles.image}
                source={{ uri: `${imageHostLink}${categories[2]?.image}` }}

                resizeMode="cover"
              />
              <View style={styles.categoryTextContainer}>
                <Text style={styles.categoryText}>{categories[2]?.name}</Text>
              </View>
            </Pressable>
          </View>
        </View>
        <View style={styles.secondImageRowWrapper}>
          <Pressable
            style={styles.secondRowImage}
            onPress={() => navigateToCategory(3)}
          >
            <Image
              style={styles.image}
              source={{ uri: `${imageHostLink}${categories[3]?.image}` }}

              resizeMode="cover"
            />
            <View style={styles.categoryTextContainer}>
              <Text style={styles.categoryText}>{categories[3]?.name}</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.secondRowImage}
            onPress={() => navigateToCategory(4)}
          >
            <Image
              style={styles.image}
              source={{ uri: `${imageHostLink}${categories[4]?.image}` }}

              resizeMode="cover"
            />
            <View style={styles.categoryTextContainer}>
              <Text style={styles.categoryText}>{categories[4]?.name}</Text>
            </View>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    marginBottom: 20,
  },
  text: {
    textTransform: "uppercase",
    fontWeight: "900",
    marginVertical: 15,
    fontSize: 13,
  },
  imageContainer: { flex: 1, gap: 10 },

  firstImageRowWrapper: {
    flexDirection: "row",
    flex: 1,
    gap: 5,
    height: 300,
  },
  firstRowImage: { flex: 1, width: "50%", gap: 10 },

  secondImageRowWrapper: {
    flexDirection: "row",
    flex: 1,
    gap: 5,
    height: 150,
  },
  secondRowImage: { flex: 1 },

  image: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primaryColor,
  },
  categoryTextContainer: {
    position: "absolute",
    bottom: 10,
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  categoryText: {
    fontWeight: "bold",
    fontSize: 16,
  }
});
