import React, { FC } from "react";
import { Input } from "react-native-elements";
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  ViewStyle,
} from "react-native";
import { IconNode } from "react-native-elements/dist/icons/Icon";
import { COLORS } from "../../../constants/colors";
import { FieldError } from "react-hook-form";

type Props = {
  inputContainerStyle?: StyleProp<ViewStyle>;
  leftIcon?: IconNode;
  rightIcon?: IconNode;
  placeholder?: string;
  errorMessage?: string;
  error?: FieldError;
  onChange?: (text: string) => void;
} & TextInputProps;

export const TextBox: FC<Props> = ({
  inputContainerStyle,
  rightIcon,
  leftIcon,
  onChange,
  errorMessage,
  error,
  onSubmitEditing,
  ...props
}) => {
  const customStyle = { ...styles.defaultInputStyle };
  return (
    <Input
      {...props}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onChangeText={onChange && onChange}
      inputStyle={styles.inputStyle}
      inputContainerStyle={[
        inputContainerStyle || customStyle,
        error && styles.inValid,
      ]}
      placeholderTextColor="black"
      errorMessage={errorMessage}
      errorStyle={styles.error}
      onSubmitEditing={onSubmitEditing}
    />
  );
};

const styles = StyleSheet.create({
  defaultInputStyle: {
    borderWidth: 1,
    borderColor: COLORS.defaultColor,
    height: 50,
    paddingHorizontal: 20,
    marginBottom: 0,
    backgroundColor: "white",
    borderRadius: 30,
  },
  inValid: {
    borderColor: COLORS.danger,
  },
  inputStyle: {
    color: COLORS.black,
    fontSize: 14,
  },
  error: {
    fontWeight: "bold",
    paddingHorizontal: 10,
    margin: 0,
  },
});
