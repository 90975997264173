import { useReducer } from "react";
import { Action, SET_ERROR, SET_LOADING, Article } from "../types";

import { getAll, getEach } from "../services/articleService";

interface State {
  loading: boolean;
  error: string | null;
  articles: Article[] | null;
  article: Article | null;
}
const SET_DATAS = "SET_DATAS";
const SET_DATA = "SET_DATA";

export interface SetDatasAction {
  type: typeof SET_DATAS;
  payload: Article[] | null;
}
export interface SetDataAction {
  type: typeof SET_DATA;
  payload: Article | null;
}

const reducer = (
  state: State,
  action: Action | SetDataAction | SetDatasAction
): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATAS:
      return { ...state, articles: action.payload };
    case SET_DATA:
      return { ...state, article: action.payload };
    default:
      return state;
  }
};

interface useArticleReturnType extends State {
  fetchDatas: () => void;
  fetchData: (id: number) => void;
}

const initialState = {
  loading: true,
  error: null,
  articles: null,
  article: null,
};

const useArticle = (): useArticleReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchDatas = async (): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const Articles = await getAll({limit:100});

      dispatch({
        type: SET_DATA,
        payload: Articles,
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  const fetchData = async (id: number): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const service = await getEach(id);

      dispatch({
        type: SET_DATA,
        payload: service,
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchData, fetchDatas };
};

export default useArticle;
