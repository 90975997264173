import { Avatar, Divider } from "react-native-elements";
import React, { FC } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Notification } from "../../../types";
import { imageHostLink } from "../../../constants/apiLinks";

type ItemProps = {
  item: Notification
};

export const NotificationItem: FC<ItemProps> = ({ item }) => {
  return (
    <>
      <View style={styles.container}>
        <Avatar rounded 
        source={{ uri: `${imageHostLink}${item?.image}` }} 

         size={40} />
        <View style={styles.content}>
          <Text style={styles.title}>{item.title}</Text>

          <Text style={styles.subTitle}>{item.description}</Text>
        </View>
      </View>
      <Divider />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
  },
  content: {
    gap: 5,
    flex: 1,
    paddingHorizontal: 10,
  },
  title: {
    fontWeight: "900",
    fontSize: 11,
    marginHorizontal: 5,
  },
  subTitle: {
    fontSize: 10,
    fontWeight: "600",
    marginHorizontal: 5,
  },
});
