import { useReducer } from "react";
import { Action, SET_ERROR, SET_LOADING, PrepaidCard } from "../types";

import { getAll, getEach } from "../services/prepaidCardservice";

interface State {
    loading: boolean;
    error: string | null;
    prepaidCards: PrepaidCard[] | null;
    prepaidCard: PrepaidCard | null;
}
const SET_DATAS = "SET_DATAS";
const SET_DATA = "SET_DATA";

export interface SetDatasAction {
    type: typeof SET_DATAS;
    payload: PrepaidCard[] | null;
}
export interface SetDataAction {
    type: typeof SET_DATA;
    payload: PrepaidCard | null;
}


const reducer = (state: State, action: Action | SetDatasAction | SetDataAction): State => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_ERROR:
            return { ...state, error: action.payload };
        case SET_DATAS:
            return { ...state, prepaidCards: action.payload };
        case SET_DATA:
            return { ...state, prepaidCard: action.payload };

        default:
            return state;
    }
};

interface usePrepaidCardType extends State {
    fetchDatas: (userId: number) => void;
    fetchData: (userId: number, id: number) => void;
}

const initialState = {
    loading: true,
    error: null,
    prepaidCards: null,
    prepaidCard:null,
};

const usePrepaidCardDetail = (): usePrepaidCardType => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchDatas = async (userId: number): Promise<boolean> => {
        try {

            dispatch({ type: SET_LOADING, payload: true });
            dispatch({ type: SET_ERROR, payload: null });
            const prepaidCards = await getAll(userId);

            dispatch({
                type: SET_DATAS,
                payload: prepaidCards,
            });
            dispatch({ type: SET_LOADING, payload: false });


            return true;
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
            console.error("Error:", error);
            return false;
        }
    };

    const fetchData = async (userId: number, id: number): Promise<boolean> => {
        try {
            dispatch({ type: SET_LOADING, payload: true });
            dispatch({ type: SET_ERROR, payload: null });
            const service = await getEach(userId,id);

            dispatch({
                type: SET_DATA,
                payload: service,
            });
            dispatch({ type: SET_LOADING, payload: false });
            return true;
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
            console.error("Error:", error);
            return false;
        }
    };

    return { ...state, fetchDatas, fetchData };
};

export default usePrepaidCardDetail;
