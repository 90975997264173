import {
  Pressable,
  StyleSheet,
} from "react-native";
import React, { FC } from "react";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from '@react-navigation/native';


type Props = {
  top?: number;
}

export const BackButton: FC<Props> = ({ top = 50 }) => {
  const navigation = useNavigation();

  const handleOnPress = () => {
    navigation.goBack()
  }
  return (
    <Pressable style={{ ...styles.backBtn, top: top }} onPress={handleOnPress}>
      <Ionicons name="arrow-back-circle" size={40} color="black" />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  backBtn: {
    position: "absolute",
    left: 10,
    zIndex: 100
  },
});
