import React, { FC, useContext, useEffect } from "react";
import { View, StyleSheet, Text, FlatList } from "react-native";
import { COLORS } from "../../constants/colors";

import { Header } from "../../components/shared/Blogs/Header";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import usePrepaidCardDetail from "../../hooks/usePrepaidCard";
import { AuthContext, AuthContextType } from "../../store/auth-context";
import { MemberCard } from "../profile/components/MemberCard";
import { BackButton } from "../../components/UI/BackButton";
import Info from "./components/Info";
import dayjs from "dayjs";

type Props = {
    route: any;
};

export const PrepaidCardScreen: FC<Props> = ({ route }) => {

    const cardID = route.params.id;

    const authCtx = useContext<AuthContextType>(AuthContext);

    const { prepaidCard, loading, fetchData } = usePrepaidCardDetail();

    console.log("prepaidCard",prepaidCard);

    useEffect(() => {
        async function loadDatas() {
            
            fetchData(authCtx?.user?.id, cardID);
        }
        loadDatas();
    }, []);

    if (loading) {
        return <LoadingOverlay message="loading..." />;
    }

    if (!prepaidCard) return <></>

    return (
        <View style={styles.container}>
            <Header title="Prepaid Card" />
            <BackButton top={10} />
            <View style={{ paddingVertical: 20 }}>
                <MemberCard prepaidCard={prepaidCard} cardWidth={100} />
            </View>
            <View style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
                <Info label="Package" value={prepaidCard.prepaidCard.name} />
                <Info label="Card Number" value={prepaidCard.card_no} />
                <Info label="Opening Balance" value={prepaidCard.prepaidCard.balance.toString()} />
                <Info label="Remaining Balance" value={prepaidCard.balance.toString()} />
            </View>
            <View style={{ display: "flex", flexDirection: "column", rowGap: 10, marginTop: 30, paddingVertical: 20, borderTopWidth: 0.5, borderTopColor: "#000" }}>
                <Info label="History" value={""} />
                <FlatList
                    ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
                    data={prepaidCard.UserPrepaidCardHistory}
                    renderItem={({ item }) => <View style={styles.historyCard}>
                        <Text style={{ ...styles.historyCardText, fontWeight: "bold", color: "red" }}>{item.amount}</Text>
                        <Text style={styles.historyCardText}>{dayjs(item.createdAt).format("DD/MM/YYYY HH:ss")}</Text>
                    </View>}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.primaryLightColor,
        paddingHorizontal: "5%"
    },
    historyCard: {
        paddingLeft: "3%",
        borderRadius: 4,
        shadowColor: '#171717',
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.5,
        elevation: 5,
        backgroundColor: COLORS.defaultColor,
        paddingHorizontal: 30,
        paddingVertical: 20,
        rowGap: 8
    },
    historyCardText: {
        fontSize: 18,
    }
});
