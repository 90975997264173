import React, { FC, useEffect } from "react";
import { View, StyleSheet, Text, ScrollView, Image } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { BackButton } from "../../components/UI/BackButton";
import { BlogDetails } from "./components/BlogDetails";
import useArticle from "../../hooks/useArticle";
import LoadingOverlay from "../../components/UI/LoadingOverlay";

type Props = {
  route: any,
  navigation: StackNavigationProp<RootStackParamList, "Blog">;
};

export const BlogScreen: FC<Props> = ({route, navigation }) => {
  const articleId = route.params.articleId;

  const { article, loading, fetchData } = useArticle();
  

  useEffect(() => {
    async function loadDatas() {
      fetchData(articleId);
    }
    loadDatas();
  }, []);
  

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  return <BlogDetails data={article}/>;
};

