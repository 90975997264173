import React, { FC, useEffect } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { Header } from "../../components/shared/Blogs/Header";
import { NotificationItem } from "./components/NotificationItem";
import useNotification from "../../hooks/useNotification";
import LoadingOverlay from "../../components/UI/LoadingOverlay";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const NotificationScreen: FC<Props> = () => {
  const { notifications, loading, fetchDatas } = useNotification();

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }
  return (
    <View style={styles.container}>
      <Header title="Notification" />
      <FlatList
        data={notifications}
        renderItem={({ item }) => <NotificationItem item={item} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.primaryLightColor,
  },
});
