import React, { FC, useEffect } from "react";
import { StyleSheet, ScrollView } from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";

import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { Blogs } from "../blog/components/BlogsSection";
import useArticlesDataFetch from "../../hooks/useArticles";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const BlogsScreen: FC<Props> = ({ navigation }) => {
  const { data, loading, fetchDatas } = useArticlesDataFetch();

  useEffect(() => {
    async function loadDatas() {
      fetchDatas();
    }
    loadDatas();
  }, []);

 
 

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  return (
    <ScrollView style={styles.container}>
      <Blogs data={data?.articles} onPress={()=>{}} allList={true} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
