import React, { FC, useEffect } from "react";
import { View, StyleSheet, FlatList, Text } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { SubCategoryServiceItem } from "./components/SubCategoryServiceItem";
import { BackButton } from "../../components/UI/BackButton";
import useSubCategory from "../../hooks/useSubCategory";
import LoadingOverlay from "../../components/UI/LoadingOverlay";

type Props = {
  route: any,
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const SubCategoryDetails: FC<Props> = ({route, navigation }) => {
  const subcategoryId = route.params.subcategoryId;

  const { loading, fetchData, subcategory } = useSubCategory(
  );

  useEffect(() => {
    async function loadDatas() {
      fetchData(subcategoryId);
    }
    loadDatas();
  }, []);
  

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  return (
    <View style={styles.container}>
      <BackButton />
      <Text style={styles.title}>
       {subcategory?.name}
      </Text>
      <FlatList
        data={subcategory?.Service}
        renderItem={({ item }) => <SubCategoryServiceItem item={item} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.defaultColor,
  },
  listContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: "900",
    margin: 20,
    marginLeft: 50,
  },
});

const list = [
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle:
      "You may be worried about losing your laser hair removal results, but don’t fret! Missing your scheduled",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle:
      "You may be worried about losing your laser hair removal results, but don’t fret! Missing your scheduled",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
  {
    title: "Botox treatments for facial wrinkles (Package 1)",
    subTitle: "မျက်နှာအရေးအကြောင်းများအတွက် Botox ကုထုံး",
    image: "https://randomuser.me/api/portraits/men/36.jpg",
  },
];
