import React, { FC } from "react";
import { View, StyleSheet, Text, Image, Dimensions, StyleProp, ViewStyle } from "react-native";
import { COLORS } from "../../../constants/colors";
import { SubmitButton } from "../../../components/UI/SubmitButton";
import { Banner } from "../../../types";
import { imageHostLink } from "../../../constants/apiLinks";



const { width, height } = Dimensions.get("window");

type Props = {
  item: Banner | null;
};
const BannerCard: FC<Props> = ({ item }) => {

  return (
    <View style={styles.cardView}>
      <Image
        style={styles.image}
        source={{ uri: `${imageHostLink}${item?.image}` }}
      />
      <View style={styles.textView}>
        <Text style={styles.title}>{item?.title}</Text>
        <Text style={styles.subtitle}>{item?.subtitle}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardView: {
    flex: 1,
    width: width,
    height: height / 3,
    backgroundColor: COLORS.primaryColor,
    justifyContent: "center",
    alignItems: "center",
  },
  textView: {
    position: "absolute",
    bottom: 20,
    margin: 10,
    left: 5,
    justifyContent: "center",
    width: "50%",
  },
  image: {
    width: width / 1.5,
    height: height / 3,
    borderRadius: 10,
    alignSelf: "flex-end",
  },
  title: {
    fontSize: 25,
    fontWeight: "900",
    lineHeight: 30,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "700",
    marginVertical: 5,
    lineHeight: 15,
  },
  button: {
    borderRadius: 0,
    width: 100,
    paddingVertical: 7,
    paddingHorizontal: 5,
    fontSize: 12,
  },
  btnText: {
    fontSize: 12,
  },
});

export default BannerCard;
