import React, { FC, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  ScrollView,
  StyleProp,
  ViewStyle,
} from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { BackButton } from "../../components/UI/BackButton";
import { SubmitButton } from "../../components/UI/SubmitButton";
import { ServiceDescription } from "./components/ServiceDescription";
import useService from "../../hooks/useService";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { imageHostLink } from "../../constants/apiLinks";
import { FavoriteIcon } from "./components/FavoriteIcon";

type Props = {
  route: any;
  navigation: StackNavigationProp<RootStackParamList, "ServiceDetails">;
};

export const ServiceDetails: FC<Props> = ({ route, navigation }) => {
  const serviceId = route.params.serviceId;
  const { loading, fetchData, service } = useService();

  useEffect(() => {
    async function loadDatas() {
      fetchData(serviceId);
    }
    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }
  const handlePress = () => { };
  return (
    <ScrollView style={styles.container}>
      <BackButton />
      <Image
        style={styles.image}
        resizeMode="cover"
        source={{ uri: `${imageHostLink}${service?.image}` }}

      />

      <View style={styles.textView}>
        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>{service?.name}</Text>
            <Text style={styles.mmTitle}>{service?.myanmarName}</Text>
          </View>
          <View>
            <FavoriteIcon navigation={navigation} isFavorite={service ? service?.isFavorite : false} service_id={service?.id as number} favoriteId={service?.isFavoriteId} />
          </View>
        </View>
        {
          service?.showPrice && <>
            {service?.discountPrice ? <View style={styles.row}>
              <Text style={styles.discountPrice}>{`${service?.price} Ks`}</Text>
              <Text style={styles.originalPrice}>{`${service?.price} Ks`}</Text>
            </View> : <View style={styles.row}>
              <Text style={{ ...styles.price, color: "black" }}>{`${service?.price} Ks`}</Text>
            </View>}
          </>
        }




      </View>
      <View style={styles.logoImageWrapper}>
        <Image
          style={styles.logoImage}
          resizeMode="contain"
          source={require("../../assets/images/logo2.png")}
        />
      </View>

      <ServiceDescription data={JSON.parse(service?.descriptions ?? '')} />

      <View style={styles.btnContainer}>
        <SubmitButton
          title="Contact Us"
          onPress={handlePress}
          customStyle={styles.button}
          customTextStyle={styles.btnText as StyleProp<ViewStyle>}
        />
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: 250,
  },
  logoImageWrapper: {
    backgroundColor: COLORS.grayColor,
    height: 50,
    paddingHorizontal: 5,
    marginVertical: 20,
  },
  logoImage: {
    width: "30%",
    height: 50,
  },
  textView: {
    flex: 1,
    justifyContent: "space-around",
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  discountPrice: {
    color: COLORS.danger,
    fontWeight: "900",
    fontSize: 18,
    width: "25%",
  },
  originalPrice: {
    textDecorationLine: "line-through",
    fontSize: 14,
    fontWeight: "900",
    flex: 1,
  },
  price: {

    fontSize: 14,
    fontWeight: "900",
    flex: 1,
  },
  title: {
    lineHeight: 18,
    fontSize: 16,
    fontWeight: "900",
    marginVertical: 3,
    flex: 1,
  },
  mmTitle: {
    lineHeight: 18,
    fontSize: 16,
    marginVertical: 3,
    flex: 1,
  },

  btnContainer: {
    marginVertical: 25,
    alignItems: "center",
  },
  button: {
    width: "70%",
    paddingVertical: 7,
    fontSize: 12,
  },
  btnText: {
    fontSize: 12,
  },
});
