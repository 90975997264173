import { getData } from "./apiService";

export async function getAll(params: any) {
  try {
    const response = await getData(`service?${new URLSearchParams(params)}`);
    return response;
  } catch (e) {1
    return false;
  }
}
export async function getEach(id: number) {
  try {
    let response = await getData(`service/${id}`);
    return response;
  } catch (e) {
    return false;
  }
}

export async function getEachByToken(id: number,token:string) {
  try {
    let response = await getData(`service/${id}`,{},token);
    return response;
  } catch (e) {
    return false;
  }
}


