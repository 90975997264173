import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  ImageBackground,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
} from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { TextBox } from "../../components/components/TextBox";
import { Ionicons } from "@expo/vector-icons";
import { SubmitButton } from "../../components/UI/SubmitButton";
import { useForm, Controller } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormData } from "./types";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import useLogin from "../../hooks/useLogin";
import { AuthContext, AuthContextType } from "../../store/auth-context";
import { COLORS } from "../../constants/colors";

const schema = object().shape({
  name: string().required("Name is required"),
  password: string().required("Password is required"),
});

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Login">;
};

export const LoginScreen: FC<Props> = ({ navigation }) => {
  const authCtx = useContext<AuthContextType>(AuthContext);
  const [hidePassword, setHidePassword] = useState(true);

  const { loading, login } = useLogin();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });


  const toggleHidePassword = () => {
    setHidePassword((prev) => !prev);
  };

  const handleSignup = () => {
    navigation.replace("Signup");
  };

  const handleExit = () => {
    navigation.replace("Home");
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      () => {
        handleExit();
        return true;
      }
    );

    return () => backHandler.remove();
  }, [handleExit]);

 

  const onSubmit = useCallback(async (data: FormData) => {
    const res = await login(data);
 
    if (!res) {
      Alert.alert(
        "Authentication failed!",
        "Could not log you in. Please check your credentials or try again later!"
      );
      return;
    }

    navigation.navigate("Home");
  }, []);

  if (loading) {
    return <LoadingOverlay message="Logging you in..." />;
  }
  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ImageBackground
        source={require("../../assets/images/Login.jpg")}
        style={styles.image}
        resizeMode="contain"
      >
        <ScrollView contentContainerStyle={styles.formContainer}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                placeholder="Email/ Phone Number"
                {...field}
                errorMessage={errors.name?.message}
                error={errors?.name}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                {...field}
                placeholder="Password"
                rightIcon={
                  <TouchableOpacity onPress={toggleHidePassword}>
                    <Icon
                      name={hidePassword ? "eye-off-outline" : "eye-outline"}
                    />
                  </TouchableOpacity>
                }
                secureTextEntry={hidePassword}
                errorMessage={errors.password?.message}
                error={errors?.password}
              />
            )}
          />

          <View style={styles.buttonWrapper}>
            <SubmitButton title="Login" onPress={handleSubmit(onSubmit)} />
          </View>

          <View style={styles.row}>
            <Text style={styles.textWrapper}>
              <Text style={styles.textStyle}>Don't have an account?</Text>

              <Text style={styles.extraBoldTextStyle} onPress={handleSignup}>
                Sign Up
              </Text>
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.textWrapper}>
              <Text style={styles.textStyle}>You want to skip?</Text>

              <Text style={styles.extraBoldTextStyle} onPress={handleExit}>
                Exit
              </Text>
            </Text>
          </View>
        </ScrollView>
      </ImageBackground>
    </KeyboardAvoidingView>
  );
};

const Icon = ({ name }: { name: keyof typeof Ionicons.glyphMap }) => {
  return <Ionicons name={name} size={20} color="black" />;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  
  },
  formContainer: {
    flexGrow: 1,
    paddingHorizontal: 20,
    gap: 5,
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  textWrapper: {
    flexDirection: "row",
    flexGrow: 1,
    textAlign: "center",
  },
  image: {
    flex: 1,
    width: "100%",
    backgroundColor:COLORS.primaryColor
  },
  textStyle: {
    fontSize: 11,
    fontWeight: "600",
    textAlign: "center",
  },
  extraBoldTextStyle: {
    fontSize: 14,
    fontWeight: "900",
  },
  buttonWrapper: {
    width: "95%",
    paddingLeft: "2.5%",
  },
});

export default LoginScreen;
