import { Avatar, Divider } from "react-native-elements";
import React, { FC, useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import { PointHistory } from "../../../types";
import { POINTS_ADD } from "../../../constants/points";
import { COLORS } from "../../../constants/colors";

type ItemProps = {
  item: PointHistory;
};

export const PointHistoryRow: FC<ItemProps> = ({ item }) => {
  const formattedDate = useMemo(() => {
    let date = new Date(item.createdAt);
    let formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }, []);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.date}>{formattedDate}</Text>
          <Text
            style={[
              styles.point,
              {
                color:
                  item.type === POINTS_ADD ? COLORS.success : COLORS.danger,
              },
            ]}
          >{`${item.type === POINTS_ADD ? "+" : "-"} ${item.point}`}</Text>
        </View>
      </View>
      <Divider />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  content: {
    gap: 5,
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 10,
    justifyContent: "space-between",
  },
  date: {
    fontSize: 13,
    color: "grey",
    fontWeight: "500",
  },
  point: {
    fontSize: 14,
    fontWeight: "900",
  },
});
