import { getData } from "./apiService";

export async function getAll(params: any) {
  try {
   
    const response = await getData(`article?${new URLSearchParams(params)}`);
    return response;
  } catch (e) {
    return false;
  }
}
export async function getEach(id: number) {
  try {
    let response = await getData(`article/${id}`);
    return response;
  } catch (e) {
    return false;
  }
}
