import React, { FC, useContext } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { Avatar } from "react-native-elements";
import { COLORS } from "../../../constants/colors";
import { AuthContext, AuthContextType } from "../../../store/auth-context";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../App";

export const Profile: FC = () => {
  const authCtx = useContext<AuthContextType>(AuthContext);


  return (
    <View style={styles.section}>
      <View style={styles.profileContainer}>
        <Avatar
          size={70}
          rounded
          source={require("../../../assets/images/person.png")}
          key={`profile`}
          containerStyle={styles.avatar}
        />
        <View style={{ width: "100%" }}>
          <Text style={styles.profileText}>{authCtx?.user?.name}</Text>
          <Text style={styles.profileText}>{authCtx?.user?.email}</Text>
        </View>
       
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    backgroundColor: COLORS.primaryColor,
    height: 200,
    width: "100%",
    paddingHorizontal: 10,
  },
  profileContainer: {
    marginTop: 20,
    width: "100%",
    alignItems: "center",
  },
  avatar: {
    borderWidth: 2,
    borderColor: COLORS.defaultColor,
  },
  profileText: {
    fontSize: 14,
    fontWeight: "600",
    textTransform: "uppercase",
    marginTop: 5,
    textAlign: "center",
  },
  editIconContainer: {
    marginTop: 5,
    alignItems: "flex-end",
    width: "100%",
  },
});
