import {
  View,
  StyleSheet,
  Text,
  StatusBar,
} from "react-native";
import React, { FC } from "react";
import { COLORS } from "../../../../constants/colors";

type Props = {
  title: string;
  backgroundColor?: string;
  titleColor?: string;
  titleFontSize?: number;
};

export const Header: FC<Props> = ({ title, backgroundColor, titleColor,titleFontSize }) => {
  return (
    <>
      <StatusBar
        barStyle="light-content"
        backgroundColor={COLORS.headerBgColor}
      />
      <View
        style={[
          styles.header,
          { backgroundColor: backgroundColor || COLORS.primaryLightColor },
        ]}
      >
       
        <Text style={[styles.title, { color: titleColor || COLORS.black }, {fontSize: titleFontSize || 15}]}>
          {title}
        </Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 55,
    width: "100%",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
  },
});
