import React, { FC, useEffect } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { COLORS } from "../../constants/colors";

import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { Header } from "../../components/shared/Blogs/Header";
import useService from "../../hooks/useService";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { ServiceCard } from "../../components/shared/Services/ServiceCard";
import { BackButton } from "../../components/UI/BackButton";

type Props = {
  route: any;
  navigation: StackNavigationProp<RootStackParamList, "Home">;
};

export const SearchedServicescreen: FC<Props> = ({ route, navigation }) => {
  const search = route.params.search;
  const { services, loading, fetchDatas } = useService();

  useEffect(() => {
    async function loadDatas() {
      fetchDatas(search);
    }
    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }
  return (
    <View style={styles.container}>
      <Header title={`Search Results: ${search}`} titleFontSize={12} />
      <BackButton />
      <FlatList
        data={services}
        contentContainerStyle={styles.cardContainer}
        renderItem={({ item }) => <ServiceCard item={item} cardWidth={280} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.primaryLightColor,
  },
  cardContainer: {
    paddingTop: 40,
    paddingBottom: 40,
    rowGap: 25,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
});
