import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, { FC, useContext, useEffect, useMemo } from "react";
import { FlatList, View,Text } from "react-native";
import useFavorite from "../../hooks/useFavorite";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { AuthContext, AuthContextType } from "../../store/auth-context";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../App";
import { Service } from "../../types";
import { FavoriteItem } from "./components/FavoriteItem";

const Tab = createMaterialTopTabNavigator();
type Props = {
  navigation: StackNavigationProp<RootStackParamList, "Favorite">;
};
export const FavoriteScreen: FC<Props> = ({ navigation }) => {
  const authCtx = useContext<AuthContextType>(AuthContext);

  const { favorites, loading, fetchDatas } = useFavorite();

  console.log("favorites",favorites);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      await loadDatas();
    });

    return unsubscribe;
  }, [navigation]);

  async function loadDatas() {
    if (authCtx.user?.id) {
      fetchDatas(authCtx.user?.id);
    }
  }

  const allData = useMemo(() => {
    let products: Service[] = [];
    for (let i in favorites) {
      products = [...products, ...favorites[i]['products']]
    }

    return products;
  }, [favorites]);


  useEffect(() => {

    loadDatas();
  }, []);

  if (loading) {
    return <LoadingOverlay message="loading..." />;
  }

  if (allData.length===0){
    return (
      <View style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80%"}}>
        <Text style={{fontSize:18,color:"black"}} >Add to your favourite list</Text>
      </View>
    )
  }

  return (
    <View>
      <FlatList
        data={allData}
        renderItem={({ item }) => <FavoriteItem item={item} />}
      />
    </View>
  );
};
