import { useReducer } from "react";
import { Action, SET_ERROR, SET_LOADING, Notification } from "../types";

import { getAll } from "../services/notificationService";

interface State {
  loading: boolean;
  error: string | null;
  notifications: Notification[] | null;
}
const SET_DATAS = "SET_DATAS";

export interface SetDatasAction {
  type: typeof SET_DATAS;
  payload: Notification[] | null;
}

const reducer = (state: State, action: Action | SetDatasAction): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATAS:
      return { ...state, notifications: action.payload };

    default:
      return state;
  }
};

interface useNotificationReturnType extends State {
  fetchDatas: () => void;
}

const initialState = {
  loading: true,
  error: null,
  notifications: null,
};

const useNotification = (): useNotificationReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchDatas = async (): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });
      const Notifications = await getAll();

      dispatch({
        type: SET_DATAS,
        payload: Notifications,
      });
      dispatch({ type: SET_LOADING, payload: false });

      // if (result?.error) {
      //   dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      //   return false;
      // }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchDatas };
};

export default useNotification;
