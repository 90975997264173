import React, { FC } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { Avatar } from "react-native-elements";
import { COLORS } from "../../../constants/colors";
import { useNavigation } from "@react-navigation/native";
import { RootStackParamList } from "../../../App";
import { StackNavigationProp } from "@react-navigation/stack";
import { Category } from "../../../types";
import { imageHostLink } from "../../../constants/apiLinks";

type Props = {
  data: Category[] | null | undefined;
};

export const Categories: FC<Props> = ({ data }) => {
  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, "Category">>();
  const navigateToCategory = (id: number) => {
    navigation.navigate("Category", {categoryId: id});
  };

  console.log(data);
  
  return (
    <View style={styles.container}>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        data={data}
        keyExtractor={(item, index) => "category-key" + index}
        style={styles.flat}
        renderItem={({ item }) => {
          return (
            <Pressable
              onPress={() => navigateToCategory(item?.id)}
              style={styles.category}
            >
              <Avatar
                size={70}
                rounded
                source={{uri: `${imageHostLink}${item?.image}`}}
                key={`profile`}
                containerStyle={styles.avatar}
              />
              <View style={styles.categoryTitleWrapper}>
                <Text style={styles.categoryTitle}>{item?.name}</Text>
              </View>
            </Pressable>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flat: {
    overflow: "visible",
  },
  avatar: {
    borderWidth: 0,
    borderColor: "none",
    elevation: 5,
    backgroundColor: COLORS.primaryColor,
  },
  category: {
    padding: 5,
    marginHorizontal: 5,
  },
  categoryTitleWrapper: { flexWrap: "wrap", width: 70 },
  categoryTitle: {
    fontSize: 11.5,
    textAlign: "center",
    marginVertical: 5,
    fontWeight: "900",
    flex: 1,
    width: "100%",
    lineHeight: 14,
  },
});
