import { getData } from "./apiService";

export async function getAll(userId:number) {
  try {
    console.log("prepaid-card?userId="+userId);
    const response = await getData("prepaid-card?userId="+userId);
    return response;
  } catch (e) {

    return false;
  }
}


export async function getEach(userId:number,prepaid_card_id:number) {
  try {
    const response = await getData("prepaid-card/"+prepaid_card_id+"?userId="+userId);
    return response;
  } catch (e) {
    return false;
  }
}

