import { useReducer } from "react";
import {
  Action,
  Article,
  Category,
  SET_ERROR,
  SET_LOADING,
  Service,
} from "../types";

import { getEach as getCategory } from "../services/categoryService";
import { getAll as getServices } from "../services/packageServiceService";
import { getAll as getArticles } from "../services/articleService";

interface CategoryDetails {
  category: Category | null;
  services: Service[] | null;
  articles: Article[] | null;
}
interface State {
  loading: boolean;
  error: string | null;
  data: CategoryDetails | null;
}
const SET_DATA = "SET_DATA";

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: CategoryDetails | null;
}

const reducer = (state: State, action: Action | SetDataAction): State => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

interface useCategoryPageDetailsReturnType extends State {
  fetchDatas: () => void;
}

const initialState = {
  loading: true,
  error: null,
  data: {
    category: null,
    services: null,
    articles: null,
  },
};

const useCategoryPageDetails = (
  categoryId: number
): useCategoryPageDetailsReturnType => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchDatas = async (): Promise<boolean> => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_ERROR, payload: null });

      const [category, services, articles] = await Promise.all([
        getCategory(categoryId),
        getServices({limit:100,categoryId:categoryId}),
        getArticles({limit:8}),
      ]);

      dispatch({
        type: SET_DATA,
        payload: { category, services, articles },
      });
      dispatch({ type: SET_LOADING, payload: false });

      if (result?.error) {
        dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
        return false;
      }
      return true;
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: "Data Fetching Failed" });
      console.error("Error:", error);
      return false;
    }
  };

  return { ...state, fetchDatas };
};

export default useCategoryPageDetails;
