import { getData } from "./apiService";

export async function getAll() {
  try {
    const response = await getData("points-history");
    return response;
  } catch (e) {
    return false;
  }
}
