import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import BottomTabNavigator from "../BottomTabNavigator";

import { BlogScreen } from "../../screens/blog";
import { CategoryScreen } from "../../screens/category";

import { LoginScreen } from "../../screens/login";
import { SignupScreen } from "../../screens/signup";
import { SubCategoryDetails } from "../../screens/subcategory/SubCategoryDetails";
import { ServiceDetails } from "../../screens/service";
import { BlogsScreen } from "../../screens/blogs";
import { PrepaidCardScreen } from "../../screens/prepaidCard";
import { MenuScreen } from "../../screens/menu";
import { SearchedServicescreen } from "../../screens/searchedServices";
import { PointHistoryScreen } from "../../screens/points-history";
const Stack = createStackNavigator();

export function PublicStack() {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="Menu" component={MenuScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="Blogs" component={BlogsScreen} />
      <Stack.Screen name="Home" component={BottomTabNavigator} />
      <Stack.Screen name="ServiceDetails" component={ServiceDetails} />
      <Stack.Screen name="SearchedServices" component={SearchedServicescreen} />
      <Stack.Screen name="PrepaidCard" component={PrepaidCardScreen} />
      <Stack.Screen name="PointHistory" component={PointHistoryScreen} />
      <Stack.Screen
        name="Blog"
        component={BlogScreen}
      
      />
      <Stack.Screen
        name="Category"
        component={CategoryScreen}
       
      />
      <Stack.Screen name="SubCategoryDetails" component={SubCategoryDetails} />
    </Stack.Navigator>
  );
}
