const np = "_lamonada_token_#$__";
import AsyncStorage from "@react-native-async-storage/async-storage";

export async function storeCache(name: string, value: string) {
  await AsyncStorage.setItem(`${np}${name}`, value);
}

export async function getCache(name: string) {
  return await AsyncStorage.getItem(`${np}${name}`);
}

export async function removeCache(name: string) {
  return await AsyncStorage.removeItem(`${np}${name}`);
}
